.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: "CeraPro-Black";
  src: local("CeraPro-Black"), url("./fonts/CeraPro-Black.ttf") format("truetype");
}
@font-face {
  font-family: "CeraPro-BlackItalic";
  src: local("CeraPro-BlackItalic"), url("./fonts/CeraPro-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "CeraPro-Bold";
  src: local("CeraPro-Bold"), url("./fonts/CeraPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "CeraPro-BoldItalic";
  src: local("CeraPro-BoldItalic"), url("./fonts/CeraPro-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "CeraPro-Light";
  src: local("CeraPro-Light"), url("./fonts/CeraPro-Light.ttf") format("truetype");
}
@font-face {
  font-family: "CeraPro-LightItalic";
  src: local("CeraPro-LightItalic"), url("./fonts/CeraPro-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "CeraPro-Medium";
  src: local("CeraPro-Medium"), url("./fonts/CeraPro-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "CeraPro-MediumItalic";
  src: local("CeraPro-MediumItalic"), url("./fonts/CeraPro-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "CeraPro-Regular";
  src: local("CeraPro-Regular"), url("./fonts/CeraPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "CeraPro-RegularItalic";
  src: local("CeraPro-RegularItalic"), url("./fonts/CeraPro-RegularItalic.ttf") format("truetype");
}
@font-face {
  font-family: "CeraPro-Thin";
  src: local("CeraPro-Thin"), url("./fonts/CeraPro-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "CeraPro-ThinItalic";
  src: local("CeraPro-ThinItalic"), url("./fonts/CeraPro-ThinItalic.ttf") format("truetype");
}
body {
  font-family: CeraPro-Regular !important;
}
