body, html { 
  font-family: 'CeraPro-Regular';
}

body {
  margin: 0;
  font-family: CeraPro-Regular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: CeraPro-Regular,
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;